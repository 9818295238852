import _ from "lodash";
import Loader from "Common/Components/Loader";
import SubHeaderTab from "Common/Components/SubHeaderTab";
import { useAppContext } from "contexts/AppContext";
import { useEffect, useState } from "react";
import { getGetOverAllReport, getStandardRawPorts } from "./action";
import SearchFilter from "./Components/SearchFilter";
import { ProgressBar } from "react-bootstrap";
import StandAloneReportTable from "./Components/StandAloneReportTable";
import "./Components/CustomStyle.css";
import { ITrackingReport } from "./types";
import moment from "moment";
import CountCard from "./Components/CountCard";

const DrayosStandAloneReport = () => {
  const { state, myDispatch } = useAppContext();
  const { overAllReports, loading, standardPorts } = state.drayosReportStates;
  const [filterParams, setFilterParams] = useState<any>(null);
  const initialParams = {
    carrierId: null,
    portCode: null,
  };
  const [detailedReport, setDetailedReport] = useState<any>({});
  const [params, setParams] = useState<any>(initialParams);
  const [filteredReports, setFilteredReports] = useState<any>(overAllReports);
  useEffect(() => {
    if (standardPorts.length === 0) {
      myDispatch(getStandardRawPorts());
    }
  }, []);

  useEffect(() => {
    if (params?.portCode?.value.length > 0) {
      let payload = {
        shipperNames: params?.portCode?.value,
        carrierId: null,
      };
      if (params?.carrierId) {
        payload.carrierId = params.carrierId.value;
      }
      myDispatch(getGetOverAllReport(payload));
    }
  }, [params]);

  useEffect(() => {
    let filteredReportDatas = [...overAllReports];
    if (filterParams?.sort) {
      let order = filterParams.sort.includes("-") ? "desc" : ("asc" as "desc" | "asc");
      filterParams.sort = filterParams.sort.replace("-", "");
      let sortby = filterParams?.sort?.split(".")[0];
      filteredReportDatas = _.orderBy(filteredReportDatas, [sortby], [order]);
    }
    if (filterParams?.filterBy) {
      if (filterParams.filterBy === "totalContainers") {
        filteredReportDatas = filteredReportDatas;
      } else if (filterParams.filterBy === "totalTracked") {
        filteredReportDatas = filteredReportDatas.filter(
          (item: any) =>
            item.lastContainerTracedDate &&
            moment(item.lastContainerTracedDate).isAfter(moment().subtract(75, "minutes"))
        );
      } else if (filterParams.filterBy === "successFullyTracked") {
        filteredReportDatas = filteredReportDatas.filter(
          (item: any) =>
            !item.errorMessage &&
            moment(item.lastContainerTracedDate).isAfter(moment().subtract(75, "minutes"))
        );
      } else if (filterParams.filterBy === "failedScrapes") {
        filteredReportDatas = filteredReportDatas.filter((item: any) =>
          item?.errorMessage?.includes("Unable")
        );
      } else if (filterParams.filterBy === "notFoundScrapes") {
        filteredReportDatas = filteredReportDatas.filter((item: any) =>
          item?.errorMessage?.includes("Container's information not found on this Port's site")
        );
      } else if (filterParams.filterBy === "problemContainers") {
        filteredReportDatas = filteredReportDatas.filter((item: any) => item?.caution);
      } else if (filterParams.filterBy === "notTracked") {
        filteredReportDatas = filteredReportDatas.filter(
          (item: any) =>
            (item.lastContainerTracedDate &&
              moment(item.lastContainerTracedDate).isBefore(moment().subtract(75, "minutes"))) ||
            !item.lastContainerTracedDate
        );
      } else if (filterParams.filterBy === "otherErrors") {
        filteredReportDatas = filteredReportDatas.filter(
          (item: any) =>
            item?.errorMessage &&
            !item?.errorMessage?.includes("Unable") &&
            !item?.errorMessage?.includes("information not found")
        );
      }
    }

    setFilteredReports(filteredReportDatas);
  }, [filterParams]);

  const getDetailedReport = (reportData: any) => {
    let detailedReportData = {
      totalContainers: reportData.length,
      totalTracked: reportData.filter(
        (item: any) =>
          item.lastContainerTracedDate &&
          moment(item.lastContainerTracedDate).isAfter(moment().subtract(75, "minutes"))
      ).length,
      successFullyTracked: reportData.filter(
        (item: any) =>
          !item.errorMessage &&
          moment(item.lastContainerTracedDate).isAfter(moment().subtract(75, "minutes"))
      ).length,
      failedScrapes: reportData.filter((item: any) => item?.errorMessage?.includes("Unable"))
        .length,
      notFoundScrapes: reportData.filter((item: any) =>
        item?.errorMessage?.includes("Container's information not found on this Port's site")
      ).length,
      successPercentage: 0,

      notTracked: reportData.filter(
        (item: any) =>
          item.lastContainerTracedDate &&
          moment(item.lastContainerTracedDate).isBefore(moment().subtract(75, "minutes"))
      ).length,
      problemContainers: reportData.filter((item: any) => item?.caution).length,
      otherErrors: reportData.filter(
        (item: any) =>
          item?.errorMessage &&
          !item?.errorMessage?.includes("Unable") &&
          !item?.errorMessage?.includes("information not found")
      ).length,
    };
    if (detailedReportData.successFullyTracked && detailedReportData.totalContainers) {
      detailedReportData.successPercentage =
        (detailedReportData.successFullyTracked / detailedReportData.totalContainers) * 100;
    }
    setDetailedReport(detailedReportData);
  };

  useEffect(() => {
    getDetailedReport(overAllReports);
    setFilteredReports(overAllReports);
  }, [overAllReports]);

  const handleFilterClick = (value: string) => {
    let filteredParamValues = { ...filterParams };
    if (value) {
      filteredParamValues.filterBy = value;
    } else {
      filteredParamValues.filterBy = null;
    }
    setFilterParams(filteredParamValues);
  };

  return (
    <div className="page-wrapper">
      <div className="position-relative subheader-wrapper">
        {loading && <Loader />}
        <SubHeaderTab val="reports" />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">Drayos StandAlone Report</h4>
              <div className="ml-auto">
                <div>Current UTC Time: {moment().utc().format("YYYY-MM-DD HH:mm:ss")}</div>
              </div>
            </div>
            <SearchFilter setParams={setParams} />
          </div>
          {params.portCode && overAllReports.length > 0 && (
            <CountCard
              detailedReport={detailedReport}
              filterParams={filterParams}
              handleFilterClick={handleFilterClick}
            />
          )}

          {Array.isArray(filteredReports) && filteredReports.length ? (
            <StandAloneReportTable
              filteredReports={filteredReports}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DrayosStandAloneReport;
