import React, { Fragment, useCallback, useEffect, useState } from "react";
import CustomSelectField, {
  SelectFieldWithoutControl,
} from "Common/Components/Inputs/CustomSelectField";
import { useAppContext } from "contexts/AppContext";
import _ from "lodash";
import { getStandardRawPorts } from "../action";

const SearchFilter = (props: any) => {
  const { state, myDispatch } = useAppContext();
  const { setParams, params } = props;
  const { loading, standardPorts } = state.drayosReportStates;
  const { drayosUsers } = state?.dashboardStates;

  const carrierOptions =
    drayosUsers?.map((opt: any) => ({
      label: opt?.email,
      value: opt?._id,
    })) || [];

  let cpPorts = standardPorts?.filter((pp: any) => pp.portCode.includes("CP"));
  let cnPorts = standardPorts?.filter((pp: any) => pp.portCode.includes("CN"));
  let csxPorts = standardPorts?.filter((pp: any) => pp.portCode.includes("CSX"));
  let upPorts = standardPorts?.filter((pp: any) => pp.portCode.includes("UP"));
  let nssPorts = standardPorts?.filter((pp: any) => pp.portCode.includes("NS "));
  let bnsfPorts = standardPorts?.filter((pp: any) => pp.portCode.includes("BNSF"));

  const shipperOptions = [
    { label: "CP Rails", value: cpPorts.map((pp: any) => pp.aliases).flat() },
    { label: "CN Rails", value: cnPorts.map((pp: any) => pp.aliases).flat() },
    { label: "CSX Rails", value: csxPorts.map((pp: any) => pp.aliases).flat() },
    { label: "UP Rails", value: upPorts.map((pp: any) => pp.aliases).flat() },
    { label: "NS Rails", value: nssPorts.map((pp: any) => pp.aliases).flat() },
    { label: "BNSF Rails", value: bnsfPorts.map((pp: any) => pp.aliases).flat() },
    ...standardPorts?.map((pp: any) => ({ label: pp.portCode, value: pp.aliases })),
  ];

  const [selectedOptions, setSelectedOptions] = useState<any>(params);

  const handleOptsChange = (e: any, type: string) => {
    const parameters = { ...selectedOptions };
    if (e.value) {
      parameters[type] = e;
      setSelectedOptions((prevParams: any) => ({
        ...prevParams,
        ...parameters,
      }));
    }
  };
  const handleOptValChange = (e: any, type: string) => {
    const parameters = { ...params };
    parameters[type] = e;
    setSelectedOptions((prevParams: any) => ({
      ...prevParams,
      ...parameters,
    }));
  };

  const handleGenerate = () => {
    setParams(selectedOptions);
  };
  return (
    <Fragment>
      <div className="filter-row d-flex align-items-center">
        <SelectFieldWithoutControl
          isLoading={false}
          // isClearable={true}
          isSearchable={true}
          name="selectPort"
          options={shipperOptions}
          onChange={(e: any) => handleOptsChange(e, "portCode")}
          size="small"
          className="min-200 mr-2"
          placeholder="Select Port"
        />
        <SelectFieldWithoutControl
          isLoading={false}
          isSearchable={true}
          isClearable={true}
          name="CARRIER"
          options={carrierOptions}
          onChange={(e: any) => {
            handleOptValChange(e, "carrierId");
          }}
          size="small"
          className="min-150 mr-3"
          placeholder="Select Carrier"
        />
        <button className="btn btn-primary" onClick={() => handleGenerate()}>
          Submit
        </button>
      </div>

      <div className="filter-row d-flex align-items-center"></div>
    </Fragment>
  );
};

export default SearchFilter;
