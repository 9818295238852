export const standAloneReportHeaders = [
  {
    label: "Email",
    value: "email",
    sort: "-",
  },
  {
    label: "REFERENCE_NUMBER",
    value: "reference_number",
    sort: "-",
  },
  {
    label: "CONTAINERNO",
    value: "containerNo",
    sort: "-",
  },
  {
    label: "SHIPPERNAME",
    value: "shipperName",
    sort: "-",
  },
  {
    label: "STATUS",
    value: "status",
    sort: "-",
  },

  {
    label: "Created At",
    value: "createdAt",
    sort: "-",
  },

  {
    label: "Last Tracked At",
    value: "lastContainerTracedDate",
    sort: "-",
  },
  {
    label: "Error Message",
    value: "errorMessage",
    sort: "-",
  },
  {
    label: "Last Free Day",
    value: "lastFreeDay",
    sort: "-",
  },
  {
    label: "VESSELETA",
    value: "vesseleta",
    sort: "-",
  },
  {
    label: "MYAPP_TRACKING_STATUS",
    value: "myapp_tracking_status",
    sort: "-",
  },
  {
    label: "PORTUSERNAME",
    value: "portusername",
    sort: "-",
  },
  {
    label: "PORTPASSWORD",
    value: "portpassword",
    sort: "-",
  },
  {
    label: "Port Note",
    value: "Port Note",
    sort: "-",
  },
  // {
  //   label: "ISUSERENTEREDVESSEL",
  //   value: "isuserenteredvessel",
  //   sort: "-",
  // },
  // {
  //   label: "BROKERHOLD",
  //   value: "brokerhold",
  //   sort: "-",
  // },
  // {
  //   label: "FREIGHT",
  //   value: "freight",
  //   sort: "-",
  // },
  // {
  //   label: "CUSTOM",
  //   value: "custom",
  //   sort: "-",
  // },
  // {
  //   label: "BOL",
  //   value: "bol",
  //   sort: "-",
  // },
  // {
  //   label: "ISUSERENTEREDSTATUS",
  //   value: "isuserenteredstatus",
  //   sort: "-",
  // },
  // {
  //   label: "MYAPP_TRACKING_STATUS",
  //   value: "myapp_tracking_status",
  //   sort: "-",
  // },
  // {
  //   label: "PORTUSERNAME",
  //   value: "portusername",
  //   sort: "-",
  // },
  // {
  //   label: "PORTPASSWORD",
  //   value: "portpassword",
  //   sort: "-",
  // },
  // {
  //   label: "CAUTIONCOUNT",
  //   value: "cautioncount",
  //   sort: "-",
  // },
  // {
  //   label: "ERRORMESSAGE",
  //   value: "errormessage",
  //   sort: "-",
  // },
  // {
  //   label: "LASTCONTAINERTRACEDDATE",
  //   value: "lastcontainertraceddate",
  //   sort: "-",
  // },
  // {
  //   label: "CARRIER",
  //   value: "carrier",
  //   sort: "-",
  // },
  //   {
  //     "label": "SHIPPERINFO",
  //     "value": "shipperinfo",
  //     "sort": "-"
  //   }
];
