import { Fragment, useState } from "react";
import { standAloneReportHeaders } from "../constant";

function StandAloneReportTable(props: {
  filteredReports: Array<Object>;
  filterParams: any;
  setFilterParams: any;
}) {
  const { filteredReports, filterParams, setFilterParams } = props;
  const [headers, setTableHeader] = useState<any | null>(standAloneReportHeaders);

  // Sorting
  const onSort = (obj: any) => {
    let sortString = ``;
    headers.map((D: any) => {
      if (D.value === obj.value) {
        D.sort = D.sort === "-" || D.sort === "" ? "+" : "-";
        sortString = `${D.sort === "+" ? "" : D.sort}${D.value}`;
        D.active = true;
      } else {
        D.active = false;
      }
      return D;
    });
    if (sortString !== "") {
      const filterParamsD = {
        ...filterParams,
        sort: sortString,
      };
      setFilterParams(filterParamsD);
      // myDispatch(setArchiveContainersParams(searchPost, searchParamsD));
    }
  };
  return (
    <Fragment>
      <div className="table-responsive" style={{ height: "calc(100vh - 255px)" }}>
        <table className="table table-card">
          <thead>
            <tr>
              <th scope="col" className="">
                SNo.
              </th>
              {standAloneReportHeaders.map((item: any, i: number) => {
                let sortIcon = "table-sort table-sort--default";
                if (item.sort === "-" && item.active) {
                  sortIcon = "table-sort table-sort--ascending";
                }
                if (item.sort === "+" && item.active) {
                  sortIcon = "table-sort table-sort--descending";
                }
                return (
                  <th key={i} onClick={() => onSort(item)} className={item.sort ? sortIcon : ""}>
                    {item.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(filteredReports) &&
              filteredReports?.length > 0 &&
              filteredReports?.map((element: any, i: number) => {
                return (
                  <Fragment key={i}>
                    <tr key={i}>
                      <td>
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="mr-1">{i + 1}</span>
                          <div className="form-check"></div>
                        </div>
                      </td>
                      <td>{element.email}</td>
                      <td>
                        {element.reference_number} &nbsp;
                        {element.cautionCount > 0 && (
                          <span
                            className={`${
                              element.caution ? "badge badge-danger" : "badge badge-success"
                            }`}
                          >
                            {element.cautionCount}
                          </span>
                        )}
                      </td>
                      <td>{element.containerNo}</td>
                      <td>{element.shipperName}</td>
                      <td>{element.status}</td>
                      <td>{element.createdAt}</td>
                      <td>{element.lastContainerTracedDate}</td>
                      <td>
                        {element.errorMessage
                          ?.split(" ")
                          .reduce((acc: any, word: string, index: number) => {
                            if (index % 10 === 0 && index !== 0) {
                              acc.push(<br key={index} />);
                            }
                            acc.push(word + " ");
                            return acc;
                          }, [])}
                      </td>
                      <td>{element.lastFreeDay}</td>
                      <td>{element.vesseleta}</td>
                      <td>{element.myApp_tracking_status}</td>
                      <td>{element.portUsername}</td>
                      <td>{element.portPassword}</td>
                      <td>
                        {element["Port Note"]
                          ?.split(" ")
                          .reduce((acc: any, word: string, index: number) => {
                            if (index % 10 === 0 && index !== 0) {
                              acc.push(<br key={index} />);
                            }
                            acc.push(word + " ");
                            return acc;
                          }, [])}
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}

export default StandAloneReportTable;
