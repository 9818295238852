import { IconAngleArrowDown, IconAngleArrowRight } from "Common/Icons";
import React, { Fragment } from "react";
import { ProgressBar } from "react-bootstrap";

interface CountCardProps {
  detailedReport: {
    successPercentage: number;
    totalContainers: number;
    totalTracked: number;
    notTracked: number;
    successFullyTracked: number;
    failedScrapes: number;
    notFoundScrapes: number;
    problemContainers: number;
    otherErrors: number;
    errorTracked: number;
  };
  filterParams: any;
  handleFilterClick: (value: string) => void;
}

const CountCard: React.FC<CountCardProps> = ({
  detailedReport,
  handleFilterClick,
  filterParams,
}) => {
  return (
    <Fragment>
      <ProgressBar
        variant="success"
        now={detailedReport.successPercentage}
        label={`${detailedReport.successPercentage.toFixed(2)}%`}
      />
      <div className={`form-row hasactive mb-3`}>
        <div className="col-xl col-md">
          <div
            className={`ncard d-flex flex-column justify-content-between p-10 ${
              filterParams?.filterBy === "totalContainers" ? "active" : ""
            }`}
          >
            <div
              className="d-flex align-items-center mb-10"
              onClick={(e) => {
                e.stopPropagation();
                handleFilterClick("totalContainers");
              }}
            >
              <div className={`mr-1 sbox sbox--default `}>
                <span>{detailedReport.totalContainers}</span>
              </div>
              <div className="font-medium">Total Containers</div>
              <div className="ml-auto">
                {filterParams?.filterBy === "totalContainers" ? (
                  <IconAngleArrowDown />
                ) : (
                  <IconAngleArrowRight />
                )}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between form-row">
              <div
                className="col-6"
                onClick={(e) => {
                  e.stopPropagation();
                  handleFilterClick("totalTracked");
                }}
              >
                <div
                  className={`bbox bbox--default ${
                    filterParams?.filterBy === "totalTracked" ? "bbox--active" : ""
                  } `}
                >
                  <div className="bbox__title">Total Tracked</div>
                  <div className="bbox__number">{detailedReport.totalTracked}</div>
                </div>
              </div>
              <div
                className="col-6"
                onClick={(e) => {
                  e.stopPropagation();
                  handleFilterClick("notTracked");
                }}
              >
                <div
                  className={`bbox bbox--default ${
                    filterParams?.filterBy === "notTracked" ? "bbox--active" : ""
                  } `}
                >
                  <div className="bbox__title">Not Tracked</div>
                  <div className="bbox__number">{detailedReport.notTracked}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl col-md">
          <div
            className={`ncard d-flex flex-column justify-content-between p-10 ${
              filterParams?.filterBy === "successFullyTracked" ? "active" : ""
            }`}
          >
            <div className="d-flex align-items-center mb-10">
              <div className={`mr-1 sbox sbox--default `}>
                <span>{`${detailedReport.successPercentage.toFixed(2)}%`}</span>
              </div>
              <div className="font-medium"> Successfully Tracked %</div>
              <div className="ml-auto">
                {filterParams?.filterBy === "successFullyTracked" ? (
                  <IconAngleArrowDown />
                ) : (
                  <IconAngleArrowRight />
                )}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between form-row">
              <div
                className="col-6"
                onClick={(e) => {
                  e.stopPropagation();
                  handleFilterClick("successFullyTracked");
                }}
              >
                <div
                  className={`bbox bbox--default ${
                    filterParams?.filterBy === "successFullyTracked" ? "bbox--active" : ""
                  } `}
                >
                  <div className="bbox__title">Successfully Tracked</div>
                  <div className="bbox__number">{detailedReport.successFullyTracked}</div>
                </div>
              </div>
              <div
                className="col-6"
                onClick={(e) => {
                  e.stopPropagation();
                  handleFilterClick("problemContainers");
                }}
              >
                <div
                  className={`bbox bbox--default ${
                    filterParams?.filterBy === "problemContainers" ? "bbox--active" : ""
                  } `}
                >
                  <div className="bbox__title">Problem Containers</div>
                  <div className="bbox__number">{detailedReport.problemContainers}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl col-md">
          <div
            className={`ncard d-flex flex-column justify-content-between p-10 ${
              filterParams?.filterBy === "errorTracked" ? "active" : ""
            }`}
          >
            <div className="d-flex align-items-center mb-10">
              <div className={`mr-1 sbox sbox--default `}>
                <span>
                  {detailedReport.notFoundScrapes +
                    detailedReport.failedScrapes +
                    detailedReport.otherErrors}
                </span>
              </div>
              <div className="font-medium">Error Tracked</div>
              <div className="ml-auto">
                {filterParams?.filterBy === "errorTracked" ? (
                  <IconAngleArrowDown />
                ) : (
                  <IconAngleArrowRight />
                )}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between form-row">
              <div
                className="col-4"
                onClick={(e) => {
                  e.stopPropagation();
                  handleFilterClick("notFoundScrapes");
                }}
              >
                <div
                  className={`bbox bbox--default ${
                    filterParams?.filterBy === "notFoundScrapes" ? "bbox--active" : ""
                  } `}
                >
                  <div className="bbox__title">Not Found</div>
                  <div className="bbox__number">{detailedReport.notFoundScrapes}</div>
                </div>
              </div>
              <div
                className="col-4"
                onClick={(e) => {
                  e.stopPropagation();
                  handleFilterClick("failedScrapes");
                }}
              >
                <div
                  className={`bbox bbox--default ${
                    filterParams?.filterBy === "failedScrapes" ? "bbox--active" : ""
                  } `}
                >
                  <div className="bbox__title">Failed to Tracked</div>
                  <div className="bbox__number">{detailedReport.failedScrapes}</div>
                </div>
              </div>
              <div
                className="col-4"
                onClick={(e) => {
                  e.stopPropagation();
                  handleFilterClick("otherErrors");
                }}
              >
                <div
                  className={`bbox bbox--default ${
                    filterParams?.filterBy === "otherErrors" ? "bbox--active" : ""
                  } `}
                >
                  <div className="bbox__title">Other Errors</div>
                  <div className="bbox__number">{detailedReport.otherErrors}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CountCard;
