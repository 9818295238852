//

import axios from "axios";
import { api } from "config";
import _ from "lodash";

import jsonToQueryParams, { objToQueryParams, removeNullEmpty } from "utils/commonFunctions";

export const publicApi = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
    // api.headers,
    xapikey: `${process.env.REACT_APP_XAPI}`,
  },
  baseURL: `${process.env.REACT_APP_PUBLIC_API}`,
  timeout: 360000,
});

const prodApi = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 360000,
});
prodApi.interceptors.request.use(function (config: any) {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  config.headers.Authorization = `${accessToken ? accessToken : refreshToken}`;
  return config;
});

export const getStandardRawPorts = () => {
  let url = "/api/standard-port/raw-ports";
  return {
    type: "STANDARD_RAW_PORTS",
    payload: prodApi.get(url),
  };
};

export const getGetOverAllReport = (payload: any) => {
  let url = "/v1/reports/baseline-tracking-report";
  return {
    type: "OVERALL_REPORT",
    payload: publicApi.post(url, payload),
  };
};

export const getBaseLineSnapShotReport = (payload: any) => {
  const { portCode, endDate, startDate } = payload;
  const snapShotReport: any = {};
  if (portCode) snapShotReport.portCode = portCode;
  if (startDate) snapShotReport.startTime = startDate;
  if (endDate) snapShotReport.endTime = endDate;

  let url = "/api/scheduler/get-overall-report-snapshot";
  return {
    type: "BASE_LINE_SNAPSHOT_REPORT",
    payload: prodApi.get(url, { params: snapShotReport }),
  };
};
