import React, { useEffect, useRef, useState } from "react";
import { IconDual, IconReset } from "../../../../Common/Components/Icons/Icons";
import { HeaderPropsI, SmallTypesI } from "../../location";
import PortImages from "../../PortImages";
import { useAppContext } from "contexts/AppContext";
import { getEmptyData, getEmptyLLMData } from "pages/EmptyReturn/action";
import {
  checkGateStatus,
  getOpenCloseInfo,
  getTimeDifference,
} from "pages/EmptyReturn/Common/utils";
import { IconWarning } from "Common/Icons";
import moment from "moment";
import { newMarketWithTwoShift, terminalNameConstant } from "pages/EmptyReturn/constant";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Loader from "Common/Components/Loader";
import { IsortData } from "pages/DrayosStandAloneReport/types";

const TableHeader = (props: HeaderPropsI) => {
  const {
    scroll,
    terminal,
    terminalCode,
    portsArray,
    emptyData,
    updateTime,
    shift,
    isTomorrow,
    date,
  } = props;
  const { state, myDispatch } = useAppContext();
  const { gatehour } = state.emptyStates;
  const { emptiesLLMData, eLoading } = state.emptyStates;

  // ---------------------------------------------------- STATE MANAGEMENT---------------------------------------------------//
  const [isScrollable, setIsScrollable] = useState(false);
  const [shiftNumber, setShiftNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [selectedPort, setSelectedPort] = useState<string>("");
  const [show, setShow] = useState(false);
  const [ports, setPorts] = useState<SmallTypesI[]>(portsArray);
  const [selectedLlmData, setSelectedLlmData] = useState<any>(null);
  const [selectedScraperData, setSelectedScraperData] = useState<any>(null);
  const [llmErrorMsg, setLlmErrorMsg] = useState<any>(null);

  // const ref = useRef<HTMLTableSectionElement>(null);
  // ---------------------------------------------------- HOOKS MANAGEMENT ---------------------------------------------------//
  useEffect(() => {
    if (scroll > 250) {
      setIsScrollable(true);
    } else {
      setIsScrollable(false);
    }
  }, [scroll]);

  useEffect(() => {
    if (shift === "shift1") {
      setShiftNumber(1);
    } else {
      setShiftNumber(2);
    }
  }, [shift]);

  const handleRefresh = () => {
    myDispatch(getEmptyData(terminalNameConstant[terminalCode || terminal], isTomorrow || false));
  };

  const gateRemarks = (filterGateData: any) => {
    switch (shift) {
      case "shift1":
        return filterGateData?.remarks1;
      case "shift2":
        return filterGateData?.remarks2;
      case "shift3":
        return filterGateData?.remarks3;
      default:
        break;
    }
  };

  function arrayBufferToBase64(arrayBuffer: ArrayBuffer) {
    const uint8Array = new Uint8Array(arrayBuffer);
    const binaryString = uint8Array.reduce((data, byte) => data + String.fromCharCode(byte), "");
    return btoa(binaryString);
  }

  const filterGateHours = (port: any) => {
    const gateData = gatehour?.find((ff: any) => ff?.key === port.label);
    const filterGateData = gateData?.data?.gateHours?.find(
      (filtered: any) => filtered.date === moment(date, "LLLL").format("LL")
    );
    return filterGateData;
  };

  useEffect(() => {
    const updatedPorts = ports.map((port) => {
      const empties = emptyData?.find((ff) => ff?.key === port.label);
      if (empties && empties.data) {
        return {
          ...port,
          s3url: empties.data.s3url || "",
          key: empties.key || "",
        };
      }
      return port;
    });

    setPorts(updatedPorts);
  }, [emptyData, portsArray]);

  const getBase64FromUrl = async (url: string): Promise<string> => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch file from URL: ${response.statusText}`);
      }
      const arrayBuffer = await response.arrayBuffer();

      const base64 = arrayBufferToBase64(arrayBuffer);
      return base64;
    } catch (error) {
      console.error("Error converting S3 URL to Base64:", error);
      throw error;
    }
  };

  const portCodeMap: Record<string, string> = {
    APM_TERMINAL: "apm-terminals",
    ITS_TERMINAL: "its-la",
    YTI: "yti-la",
    FENIX: "fenix-la",
    PIER_A: "pier-a-la",
    "EVERPORT TERMINAL": "ets-la",
    TTI: "tti",
    PCT: "pct-la",
    TRAPAC: "trapac",
    LBCT: "lbct",
    WBCT: "wbct-la",
    PNCT: "pnct-nj",
    GCT_NY: "gct-ny",
    APM: "apm-nj",
    GCT_BAYONNE: "gct-bayonne",
    MAHER_TERMINAL: "maher-terminals-njny",
    "TERMINAL 18": "terminal-18-seatac",
    "HUSKY TERMINAL & STEVEDORING": "husky-seatac",
    "TERMINAL 5": "terminal-5-seatac",
    WUT: "wut-seatac",
  };

  // List of ports that require gateHoursS3Url
  const requiredGateHoursPorts = [
    "apm-terminals",
    "its-la",
    "yti-la",
    "fenix-la",
    "pier-a-la",
    "ets-la",
    "tti",
    "pct-la",
    "trapac",
    "lbct",
    "wbct-la",
  ];
  const [base64Image, setBase64Image] = useState<string | null>(null);

  function toInternalCode(portCode: string): string {
    return portCodeMap[portCode] || portCode;
  }

  const handleShow = async (port: SmallTypesI, s3url: string) => {
    setShow(true);
    setLoading(true);

    try {
      const empties = emptyData?.find((ff) => ff?.key === port.label);
      const gateData = gatehour?.find((ff: any) => ff?.key === port.label);

      const emptiesS3Url = empties?.data?.s3url;
      const gateHoursS3Url = gateData?.data?.s3Url || gateData?.data?.s3url;

      let scraperData = null;
      if (empties && empties?.data) {
        scraperData = empties?.data?.data;

        const portCode = toInternalCode(empties?.key || "");
        if (!portCode) {
          console.error("Port code is missing in the data.");
          setLoading(false);
          return;
        }

        // Determine if gateHoursS3Url is required
        const isGateHoursRequired = requiredGateHoursPorts.includes(portCode);

        if (!emptiesS3Url || (isGateHoursRequired && !gateHoursS3Url)) {
          const errorMessage = !emptiesS3Url
            ? "S3 URL is not available for this port"
            : "Gate Hours S3 URL is required but not available for this port";

          console.error(errorMessage);
          setSelectedPort(port.label);
          setSelectedScraperData(empties?.data?.data);
          setSelectedLlmData({ error: errorMessage });
          setLoading(false);
          return;
        }

        // Convert the PDF from the S3 URL to Base64
        const emptyBase64 = await getBase64FromUrl(emptiesS3Url);
        const gateBase64 = isGateHoursRequired ? await getBase64FromUrl(gateHoursS3Url) : null;

        // Set the base64 image state
        setBase64Image(emptyBase64); // Store the base64 image

        const requestBody = {
          portCode: portCode,
          emptyReceivingFileBase64: emptyBase64,
          ...(isGateHoursRequired && { gateHoursFileBase64: gateBase64 }),
        };

        await myDispatch(getEmptyLLMData(requestBody));
      } else {
        console.error("No Scraper Data & S3 URL available for this port");
        setLlmErrorMsg("No Scraper Data & S3 URL available for this port");
        setLoading(false);
      }

      setSelectedPort(port.label);
      setSelectedScraperData(scraperData);
      setSelectedLlmData(emptiesLLMData);
    } catch (error) {
      console.error("Error in handleShow:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShow(false);
    setLoading(false);
    setSelectedPort("");
    setSelectedScraperData(null);
    setSelectedLlmData(null);
    setTableData([]);
    setPorts(portsArray);
    setLlmErrorMsg(false);
    myDispatch({ type: "RESET_E_LOADING" });
  };

  const sortData = (array1: IsortData[], array2: IsortData[]) => {
    if (!array2) return array1;
    return array1.sort((a, b) => {
      const indexA = array2.findIndex((item) => item.ssl === a.ssl && item.types === a.types);
      const indexB = array2.findIndex((item) => item.ssl === b.ssl && item.types === b.types);

      if (indexA === -1 && indexB === -1) return 0;
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;

      return indexA - indexB;
    });
  };
  return (
    <>
      <thead className={`table-head text-center ${isScrollable ? "sticky-head" : ""}`}>
        <tr>
          {isScrollable ? (
            <th rowSpan={3} className="bg-gray-100 rounded-left--5 w-0 p-2">
              <button onClick={() => handleRefresh()} className="btn btn-white w-100">
                <IconReset className="mr-10" />
                Refresh
              </button>
            </th>
          ) : (
            <th rowSpan={4} className="bg-gray-100 align-bottom rounded-left--5 w-0 p-2">
              <div className="d-flex flex-column align-items-center align-items-center">
                <div className="img-wrapper mb-40">
                  {terminalCode || terminal ? <img src={PortImages.PortLogo} alt="" /> : null}
                </div>
                <button onClick={() => handleRefresh()} className="btn btn-white w-100">
                  <IconReset className="mr-10" />
                  Refresh
                </button>
              </div>
            </th>
          )}
        </tr>

        <tr>
          {portsArray?.map((port: SmallTypesI, index: number) => {
            const empties = emptyData?.find((ff) => ff?.key === port.label);
            let s3url = "";
            if (empties && empties?.data) {
              s3url = empties?.data?.s3url;
            }
            return (
              <React.Fragment key={index}>
                {port.checked && (
                  <th
                    className="p-10 w--10"
                    onClick={() => handleShow(port, s3url)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="d-inline-flex flex-column align-items-center">
                      <div className="img-wrapper h-30px">
                        <img
                          src={PortImages[port.label.replace(/\s/g, "_")]}
                          alt={port.label}
                          className="h-100 w-100 img-contain"
                        />
                      </div>
                      <h6 className="text-primary mt-2 mb-0 title text-break-spaces">
                        {port.label.replace("_", " ")}
                      </h6>
                      {empties?.alert && (
                        <div className="position-relative ">
                          <IconWarning className="text-primary wh-10px text-warning mt-2 tooltip-icon-label" />
                          <div
                            className="tooltip tooltip--gray-700 mw-300 mt-3"
                            data-placement={
                              port.label === portsArray[portsArray.length - 1].label
                                ? "left"
                                : "bottom-center"
                            }
                          >
                            {empties.alert}
                            <span className="arrow"></span>
                          </div>
                        </div>
                      )}
                    </div>
                  </th>
                )}
              </React.Fragment>
            );
          })}
        </tr>

        {terminal === "LA" ||
          (terminalCode && newMarketWithTwoShift.includes[terminalCode] && (
            <tr className="position-sticky">
              {portsArray?.map((port: SmallTypesI, index: number) => {
                const filterGateData = filterGateHours(port);
                return (
                  <React.Fragment key={index}>
                    {port.checked && (
                      <th className="p-10 rounded-0" style={{ top: isScrollable ? "144px" : "" }}>
                        {checkGateStatus(shift, filterGateData) === "CLOSED" ? (
                          <span className="badge badge-gray-50">CLOSED</span>
                        ) : checkGateStatus(shift, filterGateData) === "OPEN" ? (
                          <span className="badge badge-light-green">OPEN</span>
                        ) : (
                          <span></span>
                        )}
                      </th>
                    )}
                  </React.Fragment>
                );
              })}
            </tr>
          ))}
        {/* <tr>
        {portsArray.map((port: SmallTypesI, index: number) => {
          let isOpen;
          const isClosed = getOpenCloseInfo(port.label, shiftNumber, terminal);
          if (terminal === "LA" && emptyData?.length) {
            const empties = emptyData?.find((ff) => ff?.key === port.label);
            if (empties && empties.data?.data?.length) {
              isOpen = empties.data.data.length;
            }
          } else if (terminal === "NY" && emptyData.length) {
            const empties = emptyData?.find((ff) => ff?.key === port.label);
            if (empties && empties.data?.data?.length) {
              isOpen = empties.data.data.length;
            }
          }
          return (
            <React.Fragment key={index}>
              {port.checked && (
                <td
                  className="p-10 rounded-0"
                  style={{ visibility: isScrollable ? "hidden" : "visible" }}
                >
                  {isOpen && isClosed ? (
                    <span className="badge badge-gray-50">CLOSED</span>
                  ) : isOpen ? (
                    <span className="badge badge-light-green">OPEN</span>
                  ) : (
                    <span className="badge badge-gray">Not Available</span>
                  )}
                </td>
              )}
            </React.Fragment>
          );
        })}
      </tr> */}
        {/* <tr>
          {portsArray.map((port: SmallTypesI, index: number) => {
            return (
              <React.Fragment key={index}>
                {port.checked && (
                  <td
                    className="py-15 px-10 rounded-0 w-10"
                    style={{ visibility: isScrollable ? "hidden" : "visible" }}
                  >
                    <span className="font-12 font-weight-normal text-dark">
                      {updateTime ? getTimeDifference(updateTime) + " min ago" : "Not Available"}
                    </span>
                  </td>
                )}
              </React.Fragment>
            );
          })}
        </tr> */}
      </thead>

      {/* Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-80w"
        centered
        aria-labelledby="modal-title"
      >
        <Modal.Header>
          {
            <Modal.Title
              id="modal-title"
              className="text-primary d-flex justify-content-between w-100"
            >
              <div>Port: {selectedPort}</div>
              <div onClick={handleClose} style={{ cursor: "pointer" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="bi bi-x"
                  width="24"
                  height="24"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 6.793l2.646-2.647a.5.5 0 1 1 .708.708L8.707 7.5l2.647 2.646a.5.5 0 0 1-.708.708L8 8.207l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 7.5 4.646 4.854a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
            </Modal.Title>
          }
        </Modal.Header>
        <Modal.Body className="overflow-y-scroll">
          {llmErrorMsg ? (
            <tr>
              <td colSpan={4} className="text-center text-danger">
                {llmErrorMsg}
              </td>
            </tr>
          ) : eLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "200px" }}
            >
              <Loader />
            </div>
          ) : (
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between">
                {/* Scraper Data */}
                <div className="scraper-data" style={{ marginRight: "20px", width: "45%" }}>
                  <h5 className="mb-3 text-success">Scraper Data</h5>
                  <Table responsive hover bordered>
                    <thead className="table-light">
                      <tr>
                        <th>SSL</th>
                        <th>Types</th>
                        <th>Shift 1</th>
                        <th>Shift 2</th>
                        <th>Shift 3</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedScraperData && selectedScraperData.length > 0 ? (
                        selectedScraperData
                          ?.sort((a: any, b: any) => a.ssl.localeCompare(b.ssl))
                          ?.map((data: any, index: any) => (
                            <tr key={index}>
                              <td>{data.ssl}</td>
                              <td>{data.types}</td>
                              <td>{data.shift1}</td>
                              <td>{data.shift2}</td>
                              <td>{data.shift3}</td>
                            </tr>
                          ))
                      ) : (
                        <tr>
                          <td colSpan={4} className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                {/* LLM Data */}
                <div className="llm-data" style={{ width: "45%" }}>
                  <h5 className="mb-3 text-info">LLM Data</h5>
                  <Table responsive hover bordered>
                    <thead className="table-light">
                      <tr>
                        <th>SSL</th>
                        <th>Types</th>
                        <th>Shift 1</th>
                        <th>Shift 2</th>
                        <th>Shift 3</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedLlmData?.error ? (
                        <tr>
                          <td colSpan={4} className="text-center text-warning">
                            <i className="bi bi-exclamation-triangle me-2"></i>
                            {selectedLlmData.error}
                          </td>
                        </tr>
                      ) : (
                        (() => {
                          const toKey = ({ ssl, types }: { ssl: string; types: string }) =>
                            `${ssl}_${types}`.toLowerCase();

                          const matchedWithShiftStatus: { shift: string; matched: boolean }[] = [];

                          // Ensure `emptiesLLMData` is an array
                          const formattedDate = moment(
                            props.date,
                            "dddd, MMMM D, YYYY h:mm A"
                          ).format("MM/DD/YYYY");
                          const llmArray = date ? emptiesLLMData[formattedDate] ?? [] : [];

                          // Ensure `selectedScraperData` is an array
                          const scraperArray = Array.isArray(selectedScraperData)
                            ? selectedScraperData
                            : [];

                          // Create a Map for fast lookup (O(1) complexity)
                          const llmDataMap = new Map();
                          llmArray.forEach((llmData: any) => {
                            const key = toKey(llmData);
                            llmDataMap.set(key, llmData);
                          });

                          // Track matched keys to filter out later
                          const matchedKeys = new Set();

                          // Process scraper data first
                          const scraperRows = scraperArray
                            .sort((a: any, b: any) => a.ssl.localeCompare(b.ssl))
                            .map((scraperData: any, index: any) => {
                              const key = toKey(scraperData);
                              const matchedData = llmDataMap.get(key); // Fast lookup

                              const newData = matchedData;
                              if (matchedData) {
                                matchedKeys.add(key);

                                const shift1Matched = matchedData.shift1 === scraperData.shift1;
                                const shift2Matched = matchedData.shift2 === scraperData.shift2;
                                const shift3Matched = matchedData.shift3 === scraperData.shift3;

                                return (
                                  <tr
                                    key={`scraper-${index}`}
                                    className={matchedData ? " text-red" : " text-red"}
                                  >
                                    <td>{matchedData ? newData.ssl : ""}</td>
                                    <td>{matchedData ? newData.types : ""}</td>
                                    <td className={`${shift1Matched ? "bg-success" : "bg-danger"}`}>
                                      {matchedData ? newData.shift1 : ""}
                                    </td>
                                    <td className={`${shift2Matched ? "bg-success" : "bg-danger"}`}>
                                      {matchedData ? newData.shift2 : "N/A"}
                                    </td>
                                    <td className={`${shift3Matched ? "bg-success" : "bg-danger"}`}>
                                      {matchedData ? newData.shift3 : "N/A"}
                                    </td>
                                  </tr>
                                );
                              }
                              return (
                                <tr
                                  key={`scraper-${index}`}
                                  className={matchedData ? " text-red" : " text-red"}
                                  style={{ height: "37px" }}
                                >
                                  <td>{matchedData ? newData.ssl : ""}</td>
                                  <td>{matchedData ? newData.types : ""}</td>
                                  <td className="bg-danger"></td>
                                  <td className="bg-danger"></td>
                                  <td className="bg-danger"></td>
                                </tr>
                              );
                            });

                          // Add unmatched LLM data at the end
                          const unmatchedRows = llmArray
                            .filter(
                              (llmData: any) =>
                                !matchedKeys.has(`${llmData.ssl}_${llmData.types}`.toLowerCase())
                            )
                            .map((llmData: any, index: any) => (
                              <tr key={`llm-${index}`}>
                                <td>{llmData.ssl}</td>
                                <td>{llmData.types}</td>
                                <td className={`${llmData.shift1Matched ?? "bg-danger"}`}>
                                  {llmData.shift1}
                                </td>
                                <td className={`${llmData.shift2Matched ?? "bg-danger"}`}>
                                  {llmData.shift2}
                                </td>
                                <td className={`${llmData.shift3Matched ?? "bg-danger"}`}>
                                  {llmData.shift3}
                                </td>
                              </tr>
                            ));

                          return [...scraperRows, ...unmatchedRows]; // Combine matched/unmatched data
                        })()
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>

              {/* Display the base64 image */}
              {base64Image && (
                <div className="base64-image mt-4" style={{ width: "100%", textAlign: "center" }}>
                  <h5 className="mb-3 text-info">Base64 Image</h5>
                  <img
                    src={`data:image/png;base64,${base64Image}`}
                    alt="Base64"
                    style={{ maxWidth: "100%" }}
                  />{" "}
                  {/* Display the image */}
                </div>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TableHeader;
